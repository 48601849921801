@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

body {
  margin: 0;
  font-family: 'Questrial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: #00CC00;
}

a:hover {
  text-decoration: none;
}

.mt0 {
  margin-top: 0;
}

.mb0 {
  margin-bottom: 0;
}

.mv0 {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
}

.text-error {
  color: #FF0000;
  font-weight: 600;
}

.text-success {
  color: #00CC00;
  font-weight: 600;
}

.call2action {
  display: block;
  margin: 20px 0;
  background-color: #00CC00;
  font-weight: 600;
  text-align: center;
  border-radius: 10px;
  padding: 10px 20px;
  font-weight: 600;
  font-size: 18px;
  width: fit-content;
  cursor: pointer;
  color: #000;
}

.call2action:hover {
  background-color: #00f700;
}

.main {
  margin: 0;
  padding: 100px 0 0 0;
  width: 100%;
  min-height: calc(100vh - 200px);
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 30px 20%;
  background-color: #FFF;
  z-index: 2;
  border-bottom: 1px solid #EEE;
  cursor: pointer;
}

header img {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 200px;
  margin-right: 6%;
}

header .menu {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  min-width: 60%;
}

header .menu .item {
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  padding: 0 2%;
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  color: #222;
}

header .menu .item:hover {
  color: #00cc00;
}

content {
  max-width: 1120px;
  display: block;
  margin: 6% auto 0 auto;
}

.visual {
  display: inline-block;
  margin: 0 20px 0 30px;
  width: 220px;
  vertical-align: top;
}

.discover {
  display: inline-block;
  width: 560px;
  vertical-align: top;
  margin-left: 10px;
  font-size: 16px;
  padding: 10px 30px;
  border-radius: 30px;
  background-color: #FAFAFA;
  min-height: 442px;
}

.discover .vigi {
  display: block;
  margin: 20px auto;
  width: 80px;
}

.discover p {
  font-weight: 500;
  line-height: 22px;
}

.benefits {
  display: block;
  width: calc(100% - 60px);
  margin: 20px auto;
  border-radius: 30px;
  padding: 30px;
  background-color: #E5EBEE;
}

.ads {
  display: inline-block;
  vertical-align: top;
  padding: 0 20px;
  height: 30px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 20px;
  background-color: #111;
  color: #FFF;
  margin-right: 20px;
  margin-bottom: 20px;
  line-height: 30px;
}

.full {
  display: block;
  margin: 6% auto 0 auto;
}

.trial {
  display: inline-block;
  vertical-align: top;
  width: 480px;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  z-index: -1;
  background-position: center center;
  background-size: cover;
  height: 590px;
  margin-left: 30px;
}

.participate {
  display: inline-block;
  width: 580px;
  margin: 0 auto;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 30px;
  background-color: #000;
  color: #FFF !important;
  height: 590px;
}

.participate .form-group {
  display: block;
  margin-bottom: 20px;
}

.participate input {
  font-family: 'Questrial', sans-serif;
  height: 34px;
  margin: 0 20px 0 0;
  border: 0;
  padding: 0 14px;
  font-size: 16px;
  border-radius: 8px;
}

.participate button {
  font-family: 'Questrial', sans-serif;
  height: 34px;
  border: 0;
  background-color: #00CC00;
  color: #000;
  padding: 0 14px;
  font-size: 16px;
  text-align: center;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
}

.participate button:hover {
  background-color: #FFF;
}

.team {
  display: block;
  width: calc(100% - 60px);
  margin: 6% auto;
  border-radius: 30px;
  padding: 30px;
  background-color: #000;
  color: #FFF;
}

.team .partner {
  margin: 4%;
  max-width: 200px;
  max-height: 80px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.contact {
  display: block;
  width: calc(100% - 60px);
  margin: 6% auto;
  border-radius: 30px;
  padding: 30px;
  background-color: #E5EBEE;
}

.stat {
  display: inline-block;
  vertical-align: top;
  min-width: 200px;
  min-height: 120px;
  margin: 20px 20px 0 0;
  border-radius: 30px;
  padding: 30px;
  background-color: #E5EBEE;
}

.panel {
  display: inline-block;
  vertical-align: top;
  min-width: 200px;
  margin: 20px 20px 0 0;
  border-radius: 30px;
  padding: 20px 30px;
  background-color: #E5EBEE;
  cursor: pointer;
}

.panel_active {
  display: inline-block;
  vertical-align: top;
  min-width: 200px;
  margin: 20px 20px 0 0;
  border-radius: 30px;
  padding: 20px 30px;
  background-color: #000;
  color: #FFF !important;
  cursor: pointer;
}

.profile {
  display: block;
  min-width: 200px;
  margin: 20px 20px 0 0;
  border-radius: 30px;
  padding: 20px 30px;
  background-color: #000;
  width: fit-content;
  color: #FFF !important;
}

.profile .selfie{
  display: block;
  margin: 0 auto 20px auto;
  width: 120px;
  height: 120px;
  border-radius: 15px;
  background-position: center center;
  background-size: cover;
}

.profile label{
  display: block;
  margin: 10px 0 5px 0;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.6em;
}

.chain {
  display: inline-block;
  vertical-align: top;
  min-width: 180px;
  min-height: 120px;
  margin: 20px 20px 0 0;
  border-radius: 30px;
  padding: 30px;
  background-color: #000;
  color: #FFF;
}

.credential {
  font-family: 'Questrial', sans-serif;
  font-size: 14px;
  display: block;
  margin: 8px 0 15px 0;
  min-width: 300px;
  padding: 10px 16px;
  border: 0;
  border-radius: 8px;
  color: #000;
  background-color: #FFF;
}

.search {
  font-family: 'Questrial', sans-serif;
  font-size: 14px;
  display: block;
  margin: 8px 0 15px 0;
  min-width: 300px;
  padding: 10px 16px;
  border: 1px solid #DDD;
  border-radius: 8px;
  color: #000;
  background-color: #FFF;
}

.details {
  border-radius: 8px;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  background-color: #E5EBEE;
  cursor: pointer;
  color: #000;
  border: 0;
  font-size: 0.8em;
  font-weight: 800;
  line-height: 30px;
}

.deactivate {
  border-radius: 8px;
  padding: 8px 10px;
  background-color: #FF0000;
  cursor: pointer;
  color: #FFF;
  border: 0;
  font-size: 11px;
  font-weight: 600;
}

.reactivate {
  border-radius: 8px;
  padding: 8px 10px;
  background-color: #00CC00;
  cursor: pointer;
  color: #000;
  border: 0;
  font-size: 11px;
  font-weight: 600;
}

.datatable {
  width: 100%;
  margin: 60px auto;
  display: block;
}

.box_press{
  display: inline-block;
  vertical-align: top;
  margin: 10px 15px;
  width: 220px;
  height: auto;
}

.box_press .visual_press{
  display: block;
  margin: 0 auto 10px auto;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  background-position: center center;
  background-size: cover;
  background-color: #FFF;
}

.box_press .desc_press{
  text-align: left;
  font-size: 13px;
  font-weight: 500;
  display: block;
}

.box_press a{
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  display: block;
  margin-top: 5px;
  text-decoration: underline;
  color: #000;
  cursor: pointer;
}

footer {
  display: block;
  width: calc(100% - 60px);
  margin: 6% auto;
  border-radius: 30px;
  padding: 30px;
  background-color: #FAFAFA;
}

footer .block {
  display: inline-block;
  vertical-align: top;
  width: 29%;
  padding: 0 2%;
}

footer .logo {
  position: relative;
  display: block;
  width: 140px;
  margin: 10px 30px 10px 0;
}

footer p {
  font-size: 14px;
  line-height: 18px;
}

@media only screen and (max-width: 1120px) {
  .main {
    padding: 0;
    overflow-x: hidden;
  }

  header {
    position: relative;
    top: 0;
    left: 0;
    width: calc(100% - 40px);
    padding: 30px 20px;
    background-color: #FFF;
    z-index: 2;
    border-bottom: 1px solid #EEE;
    cursor: pointer;
  }

  header .menu {
    margin-top: 20px;
    position: relative;
    display: block;
    min-width: 90%;
  }

  content {
    max-width: 94%;
  }

  .visual {
    margin: 30px 10px 0 10px;
    width: calc(50% - 20px);
  }

  .discover {
    display: block;
    width: calc(100% - 40px);
    margin-left: 0;
    margin-top: 8%;
  }

  .ads {
    min-height: 30px;
    height: auto;
    border-radius: 60px;
  }

  .trial {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100vw;
    border-radius: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
  }

  .participate {
    display: block;
    margin: 0 auto;
    height: auto;
    border-radius: 0;
    width: calc(100% - 60px);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  footer {
    display: block;
    width: calc(100% - 60px);
    margin: 6% auto;
    border-radius: 30px;
    padding: 30px;
    background-color: #FAFAFA;
  }

  footer .block {
    display: block;
    width: 96%;
    padding: 0 2%;
  }

  footer .logo {
    position: relative;
    display: block;
    width: 140px;
    margin: 10px 30px 10px 0;
  }

  footer p {
    font-size: 14px;
    line-height: 18px;
  }
}